
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.emptyStar {
  svg {
    filter: grayscale(1)
  }
}
.TestimonialsInner {
  .SectionName {
    color: $color-primary;
    font-weight: 700;
  }
  .SectionName, h2 {
    text-align: center;
    @include mq($breakpoint-tablet) {
      text-align-last: left;
    }
  }
  & > * {
    margin-bottom: spacing(4);
  }
}
.Testimonials {
  padding: spacing(8) 0;
  @include mq($breakpoint-tablet) {
    padding: spacing(8) spacing(10);
  }
  background-color: $color-secondary;
}


.CardSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  & > * {
    margin-bottom: spacing(3);
  }
  @include mq($breakpoint-tablet) {
    flex-direction: row;
    margin-bottom: 0;
  }
}
.Review {
  margin-left: auto;
  margin-right: auto;
  border: 5px solid $color-primary;
  padding: spacing(4);
  background-color: $color-white;
  max-width: 400px;
  
  &:nth-child(2n-1) {
    background-color: $color-semi-transparent;
  }
  
  @include mq($breakpoint-tablet) {
    width: 400px;

    &:nth-child(1) {
      margin-bottom: 25px;
      border-right: 0;
    }
    &:nth-child(2) {
      margin-bottom: 0;
    }
    &:nth-child(3) {
      margin-bottom: 50px;
      border-left: 0;
    }
  }

  .ReviewInner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .User {
      display: flex;
      align-items: center;
      margin-top: spacing(3);
      .Image {
        margin-right: spacing(2);
        width: 100px;
        height: 100px;
        img {
          border-radius: 100%;
        }
      }
    }
  }

  section {
    padding: 0;
  }
}