
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.AboutHero {
  margin-top: spacing(6);
  margin-bottom: spacing(4);
}

.Copy {
  font-weight: 500;
}

.SectionName {
  font-weight: 700;
  margin-bottom: spacing(3);
  font-size: 0.9em;
  color: $color-primary;
}

.Body {
  text-align: center;
  margin: auto;
  max-width: 450px;
  font-family: $font-body;

  h2 {
    margin-bottom: spacing(4);
    color: $color-primary;
    line-height: 50px;
    letter-spacing: -1px;
  }

  p {
    margin-bottom: spacing(4);
    color: $color-black;
    span {
      font-weight: bold;
    }
  }

  @include mq($breakpoint-tablet) {
    text-align: left;
    h2 {
      line-height: 64px;
      font-size: 3em;
    }
  }
}

.AboutPageHero {
  h2 {
    color: $color-black;
  }
}
