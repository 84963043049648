
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.BlockContent {
  a {
    color: $color-primary;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  ul {
    list-style-position: inside;
    list-style: none;
    // font-size: spacing(2.25);
    // line-height: spacing(3.25);
  
    li {
      &:before {
        content: '';
        background-color: $color-accent;
        height: spacing(1);
        border-radius: 50%;
        vertical-align: middle;
        font-weight: bold;
        display: inline-block;
        width: spacing(1);
        margin-right: spacing(1);
      }
    }
  }
}