
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Zoomable {
  transition: transform 0.2s $easing-standard;
  &:hover {
    transform: scale(1.005);
    transition: transform 0.2s $easing-standard;
  }
}