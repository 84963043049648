
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-top: 2px solid $color-primary;
  border-bottom: 2px solid $color-primary;
}

.Title {
  text-transform: uppercase;
  font-weight: 700;
  color: $color-primary;
  font-size: 0.9em;
  padding: spacing(2) 0 spacing(2);

  @include mq($breakpoint-tablet) {
    padding: spacing(3) 0 spacing(3);
  }
}

.ImageWrapper {
  max-width: 80px;
  width: 100%;
  height: 100%;
  margin: spacing(3) spacing(2);
  transition: $easing-standard $duration-shorter;

  @include mq($breakpoint-tablet) {
    margin: spacing(3) spacing(4);
    max-width: 120px;
  }

  &:hover {
    transform: scale(1.05);
    transition: $easing-standard $duration-shorter;
  }
}

.large {
  &.Wrapper {
    margin-bottom: spacing(8);
  }
  .ImageWrapper {
    max-width: 180px; 
  }
}
