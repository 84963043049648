
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
$spinner-duration: 800ms;
$spinner-step-duration: math.div($spinner-duration, 8);
$rad: radial-gradient(circle 8px, $color-primary 99%, transparent 100%);
.Spinner {
  display: block;
  animation: spinner-reveal 1000ms $easing-standard 1 forwards;
  text-align: center;
  margin: auto;
}

$spinner-width: 0.12em;

.Animation {
  box-sizing: content-box;
  padding: 0.2em;
}

.Loader {
  box-sizing: content-box;
  border-width: $spinner-width;
  border-style: solid;
  border-color: $color-primary $color-primary $color-primary transparent;
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  animation: spin 1.2s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  position: relative;

  margin: auto;

  &:before,
  &:after {
    content: '';
    width: $spinner-width;
    height: $spinner-width;
    border-radius: 50%;
    background: $color-primary;
    position: absolute;
    left: -0.02em;
  }

  &:before {
    top: 0.49em;
  }

  &:after {
    bottom: 0.49em;
  }

  .secondary & {
    border-color: $color-primary $color-primary $color-primary transparent;
    &:before,
    &:after {
      background: $color-secondary;
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes line-scale-pulse-out-rapid {
  0% {
    transform: scaley(1);
  }
  80% {
    transform: scaley(0.3);
  }
  90% {
    transform: scaley(1);
  }
}
