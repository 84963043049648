
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SpecialOfferWrapper {
  background-color: $color-secondary;
  border-radius: $radius-medium;
  padding: spacing(4);
}

.SpecialOffer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: spacing(4);
  flex-direction: column;

  .Body {
    max-width: 500px;
    font-family: $font-body;
    font-size: 1.3em;

    .BlockContent {
      display: flex;
      flex-direction: column-reverse;
      padding: 0;
    }

    .SectionName {
      font-weight: 700;
      margin-bottom: spacing(3);
      font-size: 0.7em;
      color: $color-primary;
    }
  }

  .Image {
    display: flex;
    justify-content: center;
    min-width: 240px;

    img {
      object-fit: cover;
      border-radius: $radius-xl;
    }
  }
}

.Label {
  text-transform: uppercase;
  color: $color-primary;
  font-weight: 600;
  letter-spacing: 0.1em;
  padding-bottom: spacing(4);
}

@include mq($breakpoint-tabletSmall) {
  .SpecialOfferWrapper {
    padding: spacing(12) spacing(6);
  }

  .SpecialOffer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}
