
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ButtonStandard {
  position: relative;
  padding: spacing(1.2) spacing(2);
  line-height: spacing(2.5);
  font-size: spacing(2.25);
  font-weight: 500;
  border-radius: $radius-small;
  background-color: $color-primary;
  color: $color-white;
  border: 2px solid $color-primary;
  transition: all $duration-standard $easing-standard;

  &:hover,
  &:focus {
    color: $color-white;
    transition: all $duration-standard $easing-standard;
    transform: scale(1.01);
  }
  &:active {
    transform: scale(0.99);
    transition: all $duration-standard $easing-standard;
    background-color: $color-secondary-red;
    border: 2px solid $color-secondary-red;
    color: $color-white;
  }

  @include mq($breakpoint-mobileLandscape) {
    padding: spacing(1.2) spacing(3.5);
  }
}

.secondary {
  background-color: transparent;
  border: 2px solid $color-primary;
  color: $color-black;
  span svg {
    fill: $color-primary;
  }
  &:hover,
  &:focus {
    color: $color-white;
    background-color: $color-primary;
    transition: all $duration-standard $easing-standard;
    transform: scale(1.01);
  }
  &:active {
    transform: scale(0.99);
    transition: all $duration-standard $easing-standard;
    background-color: $color-secondary-red;
    border: 2px solid $color-secondary-red;
    color: $color-white;
  }

  &:hover,
  &:active,
  &:focus {
    span svg {
      fill: $color-white;
    }
  }
}

.ButtonStandard.secondary.loading:hover :global,
.ButtonStandard.secondary.loading:active :global {
  #spinner {
    border-color: $color-white $color-white $color-white transparent;
  }
}

.ButtonStandardSpinner {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  pointer-events: none;
  // background-color: $color-white !important;
  .ButtonStandardContent {
    transition-duration: $duration-shortest;

    opacity: 0;
  }

  .ButtonStandardSpinner {
    opacity: 1;
    // margin-left: spacing(0.5);
    // margin-top:  spacing(0.5);
  }
}

.disabled {
  background-color: $color-grey85;
  border-color: $color-grey85;
}

.minimal {
  background-color: none;
  background-color: $color-white;
  border-color: $color-white;
  color: $color-black;
  &:hover,
  &:focus,
  &:active {
    background-color: $color-white-secondary;
    border-color: $color-white-secondary;
    transition: all $duration-standard $easing-standard;
    color: $color-black;
  }
  &:active {
    transform: scale(0.99);
  }
}

.small {
  padding: spacing(0.5) spacing(1.5);
  font-size: 1rem;
  font-weight: 500;
}

.mediumSmall {
  padding: spacing(1) spacing(2);
  font-size: 1rem;
  font-weight: 500;
}

.large {
  padding: spacing(2) spacing(5);
  font-size: 1.5rem;
  font-weight: 500;

  @include mq($breakpoint-desktop) {
    padding: spacing(3) spacing(8);
    font-size: 2rem;
  }
}

.medium {
  font-size: 1.5rem;
  font-weight: 500;
}
