
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.JoinUs {
  .SectionName {
    color: $color-primary;
    font-weight: bold;
  }
  h2 {
    margin: spacing(3) 0;
  }
  .Perks {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: stretch;

    @include mq($breakpoint-tablet) {
      flex-direction: row;
      margin: none;
    }
    
    .Item {
      display: flex;
      margin: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      text-align: center;
      max-width: 400px;
      min-height: 300px;
      padding: spacing(3);
      border: 5px solid $color-primary;
      @include mq($breakpoint-tablet) {
        width: 460px;
        align-items: flex-start;
        text-align: left;
      }
      .IconWrapper {
        margin-bottom: spacing(2.5);
        width: 60px;
        height: 60px;
        background-color: $color-primary;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        svg {
          vertical-align: top;
        }
      }
      .Title {
        margin-bottom: spacing(2.5);
        font-weight: bold;
      }
    }
    .Item:nth-child(even) {
      border-bottom: 0px;
      border-top: 0px;
      @include mq($breakpoint-tablet) {
        border: 5px solid $color-primary;
        border-left: 0px;
        border-right: 0px;
      }
    }
  }
}