
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
$gap-list: (
  'small': 1,
  'medium': 2,
  'mediumLarge': 4,
  'large': 4,
  'huge': 6,
);

.Stack {
  position: relative;
}

@each $key, $value in $gap-list {
  .#{$key} > * + * {
    margin-top: spacing($value - 1);

    @include mq($breakpoint-tablet) {
      margin-top: spacing($value);
    }
  }
}

// :export {
//   /* stylelint-disable-next-line property-no-unknown */
//   gaps: map-keys($gap-list);
// }
