
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ButtonBase {
  display: inline-block;
  vertical-align: middle;
  // color: inherit;
  line-height: inherit;
  text-align: center;
  text-decoration: none;
  transition: all $duration-standard $easing-standard;

  &:focus,
  &:hover {
    outline: 0;
    transition-duration: $duration-shortest;
    text-decoration: none;
  }

  // &:active {}
}

.disabled {
  user-select: none;
  pointer-events: none;
  cursor: not-allowed;
}

.block {
  display: block;
  width: 100%;
  min-width: 0 !important;
}
