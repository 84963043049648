
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Grid {
  display: grid;
  grid-template-columns: 1fr;
  margin: auto;
  width: 100%;
  margin: spacing(2) 0 spacing(2) 0;
  grid-column-gap: spacing(1);

  & > div {
    padding-bottom: spacing(1);
  }

  &.centered {
    & > div {
      margin: auto;
      width: 100%;
    }
  }

  @include mq($breakpoint-mobileLandscape) {
    grid-template-columns: 1fr 1fr;
    & > div {
      margin-bottom: auto;
    }
  }
}
