
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Block {
  margin-top: spacing(3);
  margin-bottom: spacing(4);
}

.Title {
  margin-bottom: spacing(1);
}

.Content {
  margin-top: spacing(2);
}

@include mq($breakpoint-tablet) {
  .Title {
    margin-bottom: spacing(2);
  }
}
