
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SplitPanel {
  display: flex;
  flex-direction: column;
  padding-bottom: spacing(2);
  text-align: left;

  div {
    flex: 1 1 50%;
  }

  @include mq($breakpoint-tablet) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .Left {
      margin-right: spacing(3);
    }
  }

  @include mq($breakpoint-desktop) {
    .Left {
      margin-right: spacing(5);
    }
  }

  &.vAlign-top {
    align-items: flex-start;
  }

  &.vAlign-middle {
    align-items: center;
  }

  &.vAlign-bottom {
    align-items: flex-end;
  }

  &.centerMobile {
    @include mq(null, $breakpoint-tablet) {
      text-align: center;
    }
  }
}

.reverse {
  .Left {
    @include mq($breakpoint-tablet) {
      margin-right: 0;
      margin-left: spacing(7);
      order: 2;
    }

    @include mq($breakpoint-desktop) {
      margin-left: spacing(8);
    }

    @include mq($breakpoint-desktopMedium) {
      margin-left: spacing(10);
    }
  }
}
