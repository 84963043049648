
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Title {
  margin-bottom: spacing(1);
}

@include mq($breakpoint-tablet) {
  .Title {
    margin-bottom: spacing(2);
  }
}
